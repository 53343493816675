// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Drawer, NavLink } from '@mantine/core';
import {
  IconAddressBook,
  IconCalendarEvent,
  IconChartLine,
  IconCircles, IconCreditCard,
  IconFileDescription,
  IconInbox,
  IconListCheck,
  IconLogout,
  IconMenu2,
  IconSchool,
  IconSettings,
  IconSwitchHorizontal,
} from '@tabler/icons';
import { FC, useContext } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { AuthContext } from '../../Service/Context/AuthContext';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { ReactComponent as IconBookitupCircle } from '../../Molecules/bookitup-signet.svg';
import NotificationNavIcon from '../../Molecules/Notifications/NotificationNavIcon';
import { TestS } from '../../TestS';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';
import { openForceEventCreationModal } from '../../Molecules/PrimaryNavbar';
import {LicenceS} from "../../Service/LicenceS";

const PrimaryHeader: FC = () => {
  const { hasSwitchableProfiles, switchProfile, loadingProfiles, profile } = useContext(ProfileContext);
  const navigate: NavigateFunction = useNavigate();
  const { logout } = useContext(AuthContext);
  const [opened, { open, close }] = useDisclosure(false);
  const forceEventView = false;

  // eslint-disable-next-line no-underscore-dangle
  const _switchProfile = () => {
    close();
    switchProfile();
  };

  // eslint-disable-next-line no-underscore-dangle
  const _navigate = (pathname: string, page: string) => {
    navigate(pathname);
    MixpanelS.track(ConstantS.TrackingEvents.ViewContent, { page });
  };

  return (
    <Box sx={{ height: 50, position: 'relative', boxShadow: '0px 0px 8px #0000001A' }}>
      <Drawer withCloseButton={false} opened={opened} onClose={close} padding="md" size="sm">
        <NavLink
          label="Events"
          icon={<IconCalendarEvent size={16} />}
          onClick={() => _navigate('/', 'Events')}
          data-testid={TestS.Navigate.TO_EVENTS}
        />
        <NavLink
          label="Ressourcen"
          icon={<IconCircles size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              navigate('/resources/all');
            }
          }}
          data-testid={TestS.Navigate.TO_RESOURCES}
        />
        <NavLink
          label="Aufgaben"
          icon={<IconListCheck size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              _navigate('/tasks', 'Tasks');
            }
          }}
          data-testid={TestS.Navigate.TO_TASKS}
        />
        <NavLink
          label="Kontakte"
          icon={<IconAddressBook size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              _navigate('/contacts', 'Contacts');
            }
          }}
          data-testid={TestS.Navigate.TO_CONTACTS}
        />
        <NavLink
          label="Postfach"
          icon={<IconInbox size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              _navigate('/postbox/inbox', 'Inbox');
            }
          }}
          data-testid={TestS.Navigate.TO_MAILS}
        />
        <NavLink
          label="Dokumente"
          icon={<IconFileDescription size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              _navigate('/documents', 'Documents');
            }
          }}
          data-testid={TestS.Navigate.TO_DOCUMENTS}
        />
        <NavLink
          label="Statistiken"
          icon={<IconChartLine size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              _navigate(`/statistics?year=${new Date().getFullYear}`, 'Statistics');
            }
          }}
          data-testid={TestS.Navigate.TO_STATISTICS}
        />
        <NavLink
          label="Einstellungen"
          icon={<IconSettings size={16} />}
          onClick={() => {
            if (forceEventView) {
              openForceEventCreationModal();
            } else {
              close();
              _navigate('/settings/userdata', 'Settings');
            }
          }}
          data-testid={TestS.Navigate.TO_SETTINGS}
        />
        {(profile && LicenceS.inTrialPeriod(profile.licence)) && <NavLink label="Paket & Abrechnung" icon={<IconCreditCard size={16}/>} onClick={() => {
          close();
          navigate('/settings/plans');
        }}/>}
        {!loadingProfiles && hasSwitchableProfiles && (
          <NavLink label="Profil wechseln" icon={<IconSwitchHorizontal size={16} />} onClick={_switchProfile} />
        )}
        <NavLink label="Akademie" icon={<IconSchool size={16} />} onClick={() => navigate('/academy/welcome')} />
        <NavLink
          label="Abmelden"
          icon={<IconLogout size={16} />}
          onClick={() => {
            MixpanelS.track(ConstantS.TrackingEvents.Logout);
            logout();
          }}
        />
      </Drawer>
      <Box sx={{ position: 'absolute', left: 15, top: 10 }}>
        <IconMenu2 size={32} onClick={open} />
      </Box>
      <Box sx={{ position: 'absolute', right: 5, top: 5 }}>
        <NotificationNavIcon />
      </Box>
      <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <IconBookitupCircle height={32} width={32} />
      </Box>
    </Box>
  );
};

export default PrimaryHeader;
